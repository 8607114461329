<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <snack-bar ref="snackBar" />
      <v-col
        cols="12"
        md="5"
      >
        <material-card
          icon="mdi-door"
          icon-small
          title="Welcome to Testing Log"
          color="accent"
        >
          <v-card-text>
            <v-form
              v-if="requestAdLogin===0"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="User Email"
                required
              />

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                type="password"
                label="Password"
                required
              />
            </v-form>
            <v-form v-else>
              <v-text-field
                v-model="adUser.username"
                label="AD User Name"
                required
              />

              <v-text-field
                v-model="adUser.password"
                type="password"
                label="AD Password"
                required
              />
            </v-form>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="error"
                    min-width="130"
                    block
                    @click="login()"
                  >
                    {{ loginMethod }}
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="info"
                    min-width="130"
                    block
                    @click="changeLoginMethod"
                  >
                    {{ nextLoginMethod }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-divider />
            <v-card-actions>
              <v-col cols="12">
                <div
                  id="google-login-button"
                  class="mx-auto"
                />
              </v-col>
            </v-card-actions>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import MaterialCard from '@/components/MaterialCard'
  import userAuthentication from '@/api/user-authentication'

  // Vuex
  import store from '@/store'
  import SnackBar from '@/components/app/SnackBar'
  import axios from 'axios'
  import Echo from 'laravel-echo'
  export default {
    name: 'Login',
    components: { MaterialCard, SnackBar },
    data () {
      return {
        googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        requestAdLogin: 1,
        nextLoginMethod: 'Request Email Login',
        valid: true,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: '',
        passwordRules: [
          v => !!v || 'password is required',
        ],
        adUser: {
          username: '',
          userPassword: '',
        },
      }
    },
    computed: {
      loginMethod () {
        if (this.requestAdLogin === 0) {
          return 'Email Login'
        } else {
          return 'AD Login'
        }
      },
    },
    mounted () {
      this.initGoogle()
    },
    methods: {
      initGoogle () {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: data => this.handleCredentialResponse(data),
        })
        // eslint-disable-next-line no-undef
        google.accounts.id.renderButton(
          document.getElementById('google-login-button'),
          { theme: 'outline', size: 'large' }, // customization attributes
        )
        // eslint-disable-next-line no-undef
        google.accounts.id.prompt() // also display the One Tap dialog
      },
      handleCredentialResponse (data) {
        userAuthentication.googleLogin(data.credential).then(response => {
          if (response.data.code === 200) {
            store.set('user/storeAuth', response.data.data).then(() => {
              this.createEcho()
              this.$router.push({ name: 'Dashboard' })
            })
          } else {
            store.set('message/storeMessage', {
              color: 'error',
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        })
      },
      changeLoginMethod () {
        if (this.requestAdLogin === 0) {
          this.requestAdLogin = 1
          this.nextLoginMethod = 'Request Email Login'
        } else {
          this.requestAdLogin = 0
          this.nextLoginMethod = 'Request AD Login'
        }
      },
      login () {
        switch (this.requestAdLogin) {
          case 0 :
            this.emailLogin()
            break
          case 1:
            this.adLogin()
            break
        }
      },
      emailLogin () {
        userAuthentication.login(this.email, this.password).then(response => {
          if (response.data.code === 200) {
            store.set('user/storeAuth', response.data.data).then(() => {
              this.createEcho()
              this.$router.push({ name: 'Dashboard' })
            })
          } else {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      adLogin () {
        userAuthentication.adLogin(this.adUser).then(response => {
          if (response.data.code === 200) {
            store.set('user/storeAuth', response.data.data).then(() => {
              this.createEcho()
              this.$router.push({ name: 'Dashboard' })
            })
          } else {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      requestGoogleAuth () {
      },
      createEcho () {
        window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_MIX_PUSHER_APP_KEY, // process.env.MIX_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER, // process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
          forceTLS: true,
          auth: {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('userAuthData').access_token,
            },
          },
        })
      },
    },
  }
</script>

<style scoped>
</style>
